import { Link } from 'gatsby'
import React from 'react'

function Category({title,content,next,link,style}) {
    return (
<div className={style?style:"col-md-4"}>
     <div className="rs-icon-info-1" >
         <div className="info-icon">
             <i className="fas fa-cog"></i>
         </div>
         <div className="info-text">
            <h4 className="text-black mb-2">{title}</h4>
            <p>{content}</p>
            <Link to= {link} className="btn btn-secondary">{next}</Link>
         </div>
    </div>
</div>
    )
}

export default Category
