import React from 'react'
import Banner from '../../Components/reusable/Banner'
import Layout from '../../Components/reusable/Layout'
import MyCategory from "../../Components/product/category"
function SubCategory() {
    return (
        <Layout>
            <Banner title="Sprockets" />
<section className="section my-5 ">
<div className="container">
    <div className="row my-5">
 
 
        <MyCategory
        title="AUTOMOTIVE SPROCKETS"
        content="Automotive Sprockets are made available by us in a comprehensive array for the automotive industry. Manufactured in custom specifications to meet your needs. Provided with grooves 
        that allow dirt to escape from tooth, these Automotive Sprockets have a special design that enables them to be changed quickly."
        next="view"
        link="/category/sprockets/automotive"
        />

        <MyCategory
        title="AGRICULTURAL SPROCKETS"
        content="We provide a wide range of Agricultural Sprockets, which is highly useful for agricultural machinery. The Agricultural Sprockets are manufactured and tested at our end to meet high standards of performance.
         Easing the functioning of agricultural machinery, our Agricultural Sprockets are being highly demanded all across India."
        next="view"
        link="/category/sprockets/agriculture"
        />

        <MyCategory
        title="INDUSTRIAL SPROCKETS"
        content="We bring forth a wide array of Industrial Sprockets, which is manufactured as per the industrial standards. We provide Industrial sprockets in different varieties regarding their sizes, 
        pitch and strands.  Our Industrial Sprockets offer trouble free performance and are also known for enhancing the life of the machine."
        next="view"
        link="/category/sprockets/industrial"
        />
 
         <MyCategory
        title="TEXTILE SPROCKETS"
        content="A broad array of Textile Sprockets is manufactured and supplied by us all over India. Leveraging on the power of cutting-edge technology,
        these Textile Sprockets are made to offer high performance and are known for bearing high levels of shock and stress for a longer time than other similar sprockets available in the market."
        next="view"
        link="/category/sprockets/textile"
        style="col-md-6"
        />

         <MyCategory
        title="MACHINERY SPROCKETS"
        content="Customized easily as per the specifications provided by the clients, Machinery Sprockets, manufactured by us are appreciated by a wide clientele in India.
        We use premium quality steel in manufacturing the Machinery Sprockets and test them on several parameters to ensure that they are able to give trouble-free performance for a long time."
        next="view"
        link="/category/sprockets/machinery"
        style="col-md-6"
        />


 

        </div>

           </div>
       
       
</section>
        </Layout>
    )
}

export default SubCategory
